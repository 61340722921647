import Navbar from "./components/home/Navbar";
import Hero from "./components/home/Hero";
import Info from "./components/home/Info";
import Video from "./components/home/Video";
import PertanyaanUmum from "./components/home/PertanyaanUmum";
import Footer from "./components/home/Footer";

function App() {
  return (
    <div className="App" bg="gray.200">
      <Navbar />
      <Hero />
      <Info />
      <Video />
      <PertanyaanUmum />
      <Footer />
    </div>
  );
}

export default App;
