export const handleLoginSuccess = (data) => {
  const baseUrl =
    process.env.REACT_APP_BASE_URL || "https://mbkm.fkip.umkendari.ac.id";

  localStorage.setItem("token", data.token);

  switch (data.hak_akses) {
    case "mahasiswa":
      const userData = {
        name: data.user.nama_lengkap,
        prodi: data.user.nama_prodi,
        nim: data.user.nim,
      };
      localStorage.setItem("user", JSON.stringify(userData));
      window.location.href = `${baseUrl}/mhs/beranda`;
      break;

    case "admin":
      localStorage.setItem("user", JSON.stringify(data.user));
      window.location.href = `${baseUrl}/admin`;
      break;

    case "dpl":
      localStorage.setItem("user", JSON.stringify(data.user));
      window.location.href = `${baseUrl}/dpl`;
      break;

    default:
      throw new Error("Hak akses tidak valid");
  }
};
