import React, { useEffect, useState } from "react";
import Axios from "../services/AxiosConfig";
import { handleLoginSuccess } from "helpers/authHelper";

const Callback = () => {
  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const authenticateUser = async () => {
      setIsLoading(true);

      try {
        const response = await Axios.post("sso/auth/check", { token });
        handleLoginSuccess(response.data);
      } catch (error) {
        console.error("Error authenticating user:", error);
        setIsLoading(false);
        window.location.href = process.env.REACT_APP_BASE_URL + "/login";
      }
    };

    authenticateUser();
  }, [token]);

  return (
    <div>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
        >
          <div className="loader"></div>
        </div>
      )}
      {/* Konten lainnya */}
    </div>
  );
};

export default Callback;
