import Axios from "axios";
// const instance = Axios.create({baseURL: 'https://apiunit.fkipumkendari.ac.id/'});
const instance = Axios.create({
  baseURL: "https://m2dbapi.fkip.umkendari.ac.id/",
});
const token = localStorage.getItem("token");
instance.defaults.headers.common["Authorization"] = token
  ? `Bearer ${token}`
  : "";
instance.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
export default instance;
